import React from "react";

function SvgClock({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.6c3.532 0 6.4 2.868 6.4 6.4 0 3.532-2.868 6.4-6.4 6.4A6.403 6.403 0 013.6 10c0-3.532 2.868-6.4 6.4-6.4zM10 2c-4.416 0-8 3.584-8 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm.84 7.24V5.6h-1.6v3.639a1.6 1.6 0 00.713 1.331l2.044 1.363.887-1.331-2.044-1.363z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgClock;
