import React from "react";

function SvgHide({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.456 14.374L2.48 16.346 3.636 17.5l2.286-2.28a9.638 9.638 0 004.078.9A9.676 9.676 0 0019 10a9.704 9.704 0 00-3.456-4.374l1.977-1.972L16.364 2.5l-2.286 2.28-1.245 1.24-1.774 1.77-3.275 3.266-2.158 2.152-1.17 1.166zm2.71-.394c.886.33 1.842.508 2.834.508A7.987 7.987 0 0017.216 10a8.01 8.01 0 00-2.844-3.206l-2.156 2.15A2.448 2.448 0 0110 12.448c-.38 0-.739-.086-1.059-.238l-1.775 1.77z"
        fill="currentColor"
      />
      <path d="M8.208 8.325l.112-.112a2.463 2.463 0 00-.112.112z" fill="currentColor" />
      <path
        d="M10 5.513c.328 0 .652.02.971.057l1.4-1.396A9.697 9.697 0 0010 3.88 9.676 9.676 0 001 10a9.685 9.685 0 002.196 3.322l1.15-1.146A8.064 8.064 0 012.783 10 7.996 7.996 0 0110 5.513z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHide;
