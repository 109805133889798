import React from "react";

function SvgMaximize({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M8.222 18v-1.778h-3.19l4-4-1.254-1.253-4 4v-3.191H2V18h6.222zm4-8.969l4-4v3.191H18V2h-6.222v1.778h3.19l-4 4 1.254 1.253z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMaximize;
