import React from "react";

function SvgCalendar({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6 4.4V2H14v4h-.8V4.4H7.6V2H6v4h-.8V4.4H3.6C2.718 4.4 2 5.118 2 6v10.4c0 .882.718 1.6 1.6 1.6h12.8c.882 0 1.6-.718 1.6-1.6V6c0-.882-.718-1.6-1.6-1.6h-.8zm-12 12V7.6h12.8l-.002 8.8H3.6zm8.838-7.314v.8c0 .226-.025.409-.074.548-.05.14-.097.259-.142.357l-1.695 3.647a1.174 1.174 0 01-.364.447c-.16.121-.372.182-.636.182H8.133l1.763-3.432c.086-.176.18-.334.28-.474.101-.14.212-.273.33-.4h-2.36a.402.402 0 01-.163-.034.442.442 0 01-.136-.093.49.49 0 01-.096-.135.358.358 0 01-.037-.157V9.086h4.724z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCalendar;
