import React from "react";

function SvgCharacter({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.607 14.89H10.19a.617.617 0 01-.392-.12.68.68 0 01-.221-.297l-.735-2.018H4.764l-.735 2.018a.647.647 0 01-.606.418H2L5.867 5H7.74l3.867 9.89zm-3.234-3.734H5.234L6.432 7.88c.06-.15.12-.326.18-.527.062-.2.124-.417.188-.65a12.62 12.62 0 00.374 1.163l1.199 3.29zM18 14.89h-.756a.81.81 0 01-.374-.071c-.091-.048-.16-.145-.204-.291l-.15-.5c-.177.16-.35.3-.521.421-.17.121-.346.223-.528.305a2.683 2.683 0 01-.579.184 3.46 3.46 0 01-.68.062c-.295 0-.568-.04-.817-.12a1.76 1.76 0 01-.644-.359 1.631 1.631 0 01-.418-.595 2.12 2.12 0 01-.15-.828c0-.264.069-.525.207-.783.139-.257.37-.49.691-.697.323-.208.752-.38 1.287-.517.536-.137 1.2-.214 1.995-.232v-.41c0-.47-.099-.818-.296-1.044-.197-.226-.485-.338-.861-.338-.273 0-.5.032-.681.095-.182.064-.34.136-.473.216-.134.08-.258.151-.371.215a.75.75 0 01-.375.096.485.485 0 01-.293-.089.699.699 0 01-.197-.219l-.306-.54c.803-.739 1.772-1.108 2.907-1.108.408 0 .773.067 1.093.202.32.134.59.321.813.56.223.24.392.526.507.859.116.333.174.698.174 1.094v4.433zm-3.268-1.053c.172 0 .331-.016.477-.048.145-.032.282-.08.411-.143a2.19 2.19 0 00.375-.236c.12-.094.242-.204.364-.332v-1.183c-.49.023-.9.065-1.229.126-.329.062-.593.14-.793.236-.2.096-.341.208-.425.335a.744.744 0 00-.127.418c0 .296.088.508.263.636.174.127.402.191.684.191z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCharacter;
