import React from "react";

function SvgCaution({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 15.795a1.001 1.001 0 010-2.004 1.001 1.001 0 010 2.004zM9.2 8.38h1.6v4.81H9.2V8.38zm8.716 8.46l-7.2-14.431c-.271-.544-1.16-.544-1.432 0l-7.2 14.432A.802.802 0 002.8 18h14.4a.802.802 0 00.716-1.16z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCaution;
