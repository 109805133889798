import React from "react";

function SvgDragHandle({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M8.4 2H6v2.286h2.4V2zM8.4 6.571H6v2.286h2.4V6.571zM6 11.143h2.4v2.286H6v-2.286zM8.4 15.714H6V18h2.4v-2.286zM11.6 2H14v2.286h-2.4V2zM14 6.571h-2.4v2.286H14V6.571zM11.6 11.143H14v2.286h-2.4v-2.286zM14 15.714h-2.4V18H14v-2.286z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDragHandle;
