import React from "react";

function SvgSort({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.391 7.202L10.609 6 6.555 2 2.5 6l1.218 1.202 1.975-1.949v9.846h1.723V5.253L9.39 7.202zM16.282 12.798l-1.975 1.949V4.9h-1.723v9.846l-1.975-1.949L9.391 14l4.054 4 4.055-4-1.218-1.202z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSort;
