import React from "react";

function SvgClipboard({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.361 4.4v12h9.666V18H4.361a1.607 1.607 0 01-1.611-1.6v-12h1.611zM13.556 2l3.694 3.669V13.2c0 .882-.723 1.6-1.611 1.6H7.584a1.607 1.607 0 01-1.611-1.6V3.6c0-.882.722-1.6 1.61-1.6h5.973zm-1.14 4.8V3.6H7.585v9.6h8.056l-.001-6.4h-3.222z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgClipboard;
