import React from "react";

function SvgFilter({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M9.167 15h1.666a.836.836 0 00.834-.833.836.836 0 00-.834-.834H9.167a.836.836 0 00-.834.834c0 .458.375.833.834.833zM2.5 5.833c0 .459.375.834.833.834h13.334a.836.836 0 00.833-.834.836.836 0 00-.833-.833H3.333a.836.836 0 00-.833.833zm3.333 5h8.334A.836.836 0 0015 10a.836.836 0 00-.833-.833H5.833A.836.836 0 005 10c0 .458.375.833.833.833z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFilter;
