import React from "react";

function SvgEllipsisVertical({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M10 15a2 2 0 11.001 3.999A2 2 0 0110 15zM10 8a2 2 0 11.001 3.999A2 2 0 0110 8zM12 3a2 2 0 10-3.999-.001A2 2 0 0012 3z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgEllipsisVertical;
