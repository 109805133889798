import React from "react";

function SvgUpload({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.049 9.497c.005-.093.015-.188.015-.282 0-2.605-2.128-4.715-4.756-4.715a4.757 4.757 0 00-4.292 2.69 2.177 2.177 0 00-1.344-.456 2.184 2.184 0 00-2.194 2.174c0 .228.035.446.1.649A3.18 3.18 0 002 12.3c0 1.748 1.414 3.165 3.172 3.19H9.05v-3.072H7.012c-.315 0-.41-.198-.205-.435L9.59 8.69a.466.466 0 01.74 0l2.783 3.294c.205.243.11.435-.205.435H10.87v3.071h3.812c.055.005.115.01.17.01 1.739 0 3.148-1.396 3.148-3.12a3.147 3.147 0 00-1.948-2.883h-.003z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgUpload;
