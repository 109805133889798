import React from "react";

function SvgUnlink({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M2 4.09l1.064-1.057 13.903 13.903L15.91 18l-4.016-4.016-2.17 2.17a4.16 4.16 0 01-5.88 0 4.16 4.16 0 010-5.879l1.24-1.238a5.82 5.82 0 00.332 2.02l-.39.39a2.48 2.48 0 000 3.526 2.48 2.48 0 003.525 0l2.179-2.162-1.347-1.338a.888.888 0 01-.241.565.838.838 0 01-1.181 0 4.135 4.135 0 01-1.206-3.184L2 4.089zm8.905-.874a4.16 4.16 0 015.879 0 4.16 4.16 0 010 5.879l-1.239 1.239a5.745 5.745 0 00-.333-2.013l.391-.399a2.48 2.48 0 000-3.525 2.48 2.48 0 00-3.525 0l-2.77 2.769-1.172-1.181 2.77-2.769zm.582 4.116a.838.838 0 011.181 0 4.156 4.156 0 011.023 4.207l-1.48-1.471a2.422 2.422 0 00-.724-1.555.81.81 0 010-1.181z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgUnlink;
