import React from "react";

function SvgTrashbin({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.006 14.8H6.677V7.6h1.33v7.2zm2.659 0h-1.33V7.6h1.33v7.2zm2.658 0h-1.33V7.6h1.33v7.2zM14.386 6H5.614v10.4h8.772V6zM7.875 4.362h4.266l-.308-.827a.37.37 0 00-.176-.125H8.369a.376.376 0 00-.177.125l-.317.827zM17.5 5.675a.324.324 0 01-.094.24.29.29 0 01-.238.085H15.98v10.4c0 .876-.722 1.6-1.595 1.6H5.614a1.607 1.607 0 01-1.595-1.6V6h-1.17a.29.29 0 01-.238-.086.433.433 0 01-.111-.24v-.97a.424.424 0 01.11-.24.372.372 0 01.24-.102h3.394l.726-1.424c.104-.257.29-.476.56-.656.27-.18.543-.27.82-.282h3.319c.276.012.55.102.82.282.27.18.456.399.56.656l.726 1.424h3.395c.097.009.176.04.238.102a.32.32 0 01.092.24v.971z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTrashbin;
