import React from "react";

function SvgNumber({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.403 13.277L11.444 18h-.925a.595.595 0 01-.445-.19.68.68 0 01-.182-.491c0-.03.001-.058.005-.084a1.74 1.74 0 01.017-.095l.811-3.863h-2.82l-.81 3.964c-.062.275-.178.47-.349.586a1.028 1.028 0 01-.588.173h-.902l.97-4.723H4.56c-.175 0-.312-.043-.41-.128-.1-.086-.149-.233-.149-.441a1.453 1.453 0 01.023-.257l.091-.637h2.33l.742-3.64h-2.65l.15-.826c.037-.216.125-.376.262-.48.137-.104.354-.156.65-.156h1.804l.823-3.997a.854.854 0 01.314-.525.933.933 0 01.588-.19h.913l-.96 4.712h2.821L12.861 2h.901c.19 0 .348.056.474.167a.555.555 0 01.188.436c0 .06-.003.108-.011.145l-.834 3.964H16l-.148.837c-.039.216-.128.374-.269.475-.14.1-.356.15-.645.15h-1.575l-.743 3.64h2.044c.183 0 .324.043.423.129.098.085.148.236.148.452a1.27 1.27 0 01-.023.246l-.103.636h-2.706zm-4.281-1.463h2.82l.742-3.64h-2.82l-.742 3.64z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgNumber;
