import React from "react";

function SvgPencil({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.058 2.942A3.2 3.2 0 0014.78 2c-.86 0-1.67.334-2.277.943l-9.128 9.128a.795.795 0 00-.21.371l-1.142 4.564a.8.8 0 00.97.97l4.565-1.142a.796.796 0 00.372-.21l9.127-9.128A3.204 3.204 0 0018 5.22c0-.86-.334-1.67-.943-2.277zM7.66 13.47l5.978-5.977.581.58-6.364 6.365-.195-.967zm-1.13-1.131l-.968-.193 6.365-6.365.58.581-5.978 5.977zM4.531 13.57l1.581.316.317 1.582-2.53.633.632-2.53zM13.06 4.65l.576-.574c.613-.612 1.68-.612 2.291 0l-.001-.001c.612.612.612 1.679 0 2.291l-.575.576L13.06 4.65z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPencil;
