import React from "react";

function SvgArrowRightB({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.549 11.056H3V8.944h10.549l-2.716-2.701 1.485-1.493s2.841 2.817 4.272 4.255c.547.55.547 1.44 0 1.99-1.43 1.438-4.272 4.255-4.272 4.255l-1.485-1.493 2.716-2.701z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgArrowRightB;
